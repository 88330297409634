import React from 'react';
import '../App.css';
import { Image } from 'react-bootstrap';
import learn from '../img/audio-course.png';
import practice from '../img/practice.png';
import ask from '../img/ask.png';
// import blob from '../img/blob.svg';
import pattern from '../img/pattern.png';
import { useHistory } from "react-router-dom";

function Section() {
    const history = useHistory();
    return (
        <section style={{ width: '100vw'}}>
            {/* <Image draggable="false" src={blob} className="img2"></Image> */}
            <div className="container2 d-flex flex-column align-items-center py-5" style={{backgroundColor:'#282728'}}>
                <div className="d-flex flex-column align-items-center justify-content-center container mt-4 mb-4 col-10 col-lg-9">
                    <h3 className="m-0 p-0 text-center font-weight-lighter text-capitalize mb-2 text-white" style={{ fontSize: '1.2em' }}>classroom</h3>
                    <h1 className="font-weight-bolder text-center m-0" style={{ fontSize: '4em', color:'white'}}>Experience a physical classroom <br/>with<span className="textColor"> Stuid Learning App</span></h1>
                </div>
                <div className="col-10 d-flex flex-lg-row flex-column align-items-center justify-content-center mt-4 mb-4 pt-4 pb-4">

                    <div onClick={() => history.push('./Learning')} className="featureBox col-lg-4 col-12 col-md-10 shadow-sm d-flex justify-content-center align-items-center flex-column p-4 mb-4 mx-2" style={{ borderRadius: '1em', cursor:'pointer' }}>
                        <div className="shadow-lg d-flex justify-content-center align-items-center bg-color" style={{ height: '65px', width: '65px', borderRadius: '13px' }}>
                            <Image draggable="false" src={learn} style={{ width: '2rem', height: 'auto' }}></Image>
                            <Image draggable="false" className="ml-4" src={pattern} style={{ height: 'auto', width: '3.5rem', position: 'absolute', opacity: '0.1',  zIndex: -1, top: '3rem', alignSelf:'center'}}></Image>
                        </div>
                        <h2 className="font-weight-bold mb-4 mt-4" style={{ fontSize: '2em' }}>Learn</h2>
                        <h3 className="font-weight-lighter mb-4 text-center" style={{ fontSize: '1.2em' }}>Audio, video, and textual presentation of the concepts</h3>
                        <h3 className="font-weight-bold mb-4 text-uppercase textColor1" style={{ fontSize: '1.5em' }}>View<i className="fa fa-long-arrow-right ml-2"></i></h3>
                    </div>

                    <div onClick={() => history.push('./Ask')} className="featureBox col-lg-4 col-12 col-md-10 shadow-sm d-flex justify-content-center align-items-center flex-column p-4 mb-4 mx-2" style={{ borderRadius: '1em', cursor:'pointer' }}>
                        <div className="shadow-lg d-flex justify-content-center align-items-center bg-color2" style={{ height: '65px', width: '65px', borderRadius: '13px' }}>
                            <Image draggable="false" src={ask} style={{ width: '2rem', height: 'auto' }}></Image>
                            <Image draggable="false" className="ml-4" src={pattern} style={{ height: 'auto', width: '3.5rem', position: 'absolute', opacity: '0.1',  zIndex: -1, top: '3rem', alignSelf:'center'}}></Image>
                        </div>
                        <h2 className="font-weight-bold mb-4 mt-4" style={{ fontSize: '2em' }}>Ask</h2>
                        <h4 className="font-weight-lighter mb-4 text-center" style={{ fontSize: '1.2em' }}>Clarify your doubts and understand the concept easily</h4>
                        <h3 className="font-weight-bold mb-4 text-uppercase textColor2" style={{ fontSize: '1.5em' }}>View<i className="fa fa-long-arrow-right ml-2"></i></h3>
                    </div>

                    <div onClick={() => history.push('./Test')} className="featureBox col-lg-4 col-12 col-md-10 shadow-sm d-flex justify-content-center align-items-center flex-column p-4 mb-4 mx-2" style={{ borderRadius: '1em', cursor:'pointer' }}>
                        <div className="shadow-lg d-flex justify-content-center align-items-center bg-color3" style={{ height: '65px', width: '65px', borderRadius: '13px' }}>
                            <Image draggable="false" src={practice} style={{ width: '2rem', height: 'auto' }}></Image>
                            <Image draggable="false" className="ml-4" src={pattern} style={{ height: 'auto', width: '3.5rem', position: 'absolute', opacity: '0.1',  zIndex: -1, top: '3rem', alignSelf:'center'}}></Image>
                        </div>
                        <h2 className="font-weight-bold mb-4 mt-4" style={{ fontSize: '2em' }}>Test</h2>
                        <h4 className="font-weight-lighter mb-4 text-center" style={{ fontSize: '1.2em' }}>Evaluate what is learned with our interactive fun test series</h4>
                        <h3 className="font-weight-bold mb-4 text-uppercase textColor3" style={{ fontSize: '1.5em' }}>View<i className="fa fa-long-arrow-right ml-2"></i></h3>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Section;