import React from 'react';
import Footer from '../Components/Footer';


function Copyright() {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <section className="d-flex flex-column justify-content-center align-items-center py-5" style={{ backgroundColor: '#f2f2f2', scrollMarginTop: '5rem' }}>
                <div class="container-fluid">
                    <div class="d-flex flex-column justify-content-center align-items-center">
                        <div class="col-xs-12 text-center pt-5  mx-5">
                            <h1 class="title">Copyright Policy</h1>

                            <br></br>

                        </div>

                        <div class="col-xs-12 col-sm-12  col-sm-offset-1 col-md-10 col-md-offset-2" style={{ width: '100vw' }}>
                            <div class="content px-3">
                                <p>You recognize and acknoweldge that the ownership of all trademarks, copyright, logos, service marks and other intellectual property owned by any third party shall continue to vest with such party and You are not permitted to use the same without the consent of the respective third party.<br />
                                    <br /><ul>
                                        <li>     You may not decompile, reverse engineer, or disassemble the contents of the Application and / or our Website and/or Services/ products or modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information or software obtained from the Application and / or our Website and/or Services/products, or remove any copyright, trademark registration, or other proprietary notices from the contents of the Application and / or and / or our Website and/or Services/products.<br />
                                        </li><li>   You will not (a) use this Application and / or our Website and/or any of our product/s or Service/s for commercial purposes of any kind, or (b) advertise or sell the Application or any products, Services or domain names or otherwise (whether or not for profit), or solicit others (including, without limitation, solicitations for contributions or donations) or use any public forum for commercial purposes of any kind, or (c) use the Application and / or Website/our products and Services in any way that is unlawful, or harms the LLP or any other person or entity as determined by the LLP.<br />
                                        </li><li>     No User shall be permitted to perform any of the following prohibited activities while availing our Services:<br />
                                        </li><li>    Making available any content that is misleading, unlawful, harmful, threatening, abusive, tortious, defamatory, libelous, vulgar, obscene, child-pornographic, lewd, lascivious, profane, invasive of another's privacy, hateful, or racially, ethnically or otherwise objectionable;<br />
                                        </li><li>    Stalking, intimidating and/or harassing another and/or inciting other to commit violence;<br />
                                        </li><li>  Transmitting material that encourages anyone to commit a criminal offence, that results in civil liability or otherwise breaches any relevant laws, regulations or code of practice;<br />
                                        </li><li>  Interfering with any other person's use or enjoyment of the Application/Website/Services;<br />
                                        </li><li> Making, transmitting or storing electronic copies of materials protected by copyright without the permission of the owner, committing any act that amounts to the infringement of intellectual property or making available any material that infringes any intellectual property rights or other proprietary rights of anyone else;</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer></Footer>
        </>
    )
} export default Copyright;