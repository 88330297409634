import React from 'react';
import { Image, Form, Button } from 'react-bootstrap';
import Contact from '../img/contactUs.webp';

function ContactUs() {
    return (
        <section id="ContactUs" className="pb-4 d-flex justify-content-center align-items-center flex-column" style={{ width: '100vw', backgroundColor: '#282728', scrollMarginTop: '5rem' }}>
            {/* <Image src={wave} style={{ width: '100vw' }}></Image> */}
            <div className="d-flex flex-column align-items-center justify-content-center pb-4 pt-4 col-9" style={{ width: '100vw' }}>
                <h5 className="font-weight-bolder text-capitalize pt-4 m-0" style={{ color: 'white' }}>get in touch</h5>
                <p style={{ fontSize: '1.1em', lineHeight: '2em' }} className="text-capitalize font-weight-light text-white pt-4 text-center">dont be shy. give us a call or drop us a line</p>
            </div>
            <div className="d-flex justify-content-center align-items-center flex-lg-row flex-column col-lg-10 col-9 col-md-8 mt-4 p-4" style={{ backgroundColor: 'white', borderRadius: '1rem', overflow: 'hidden' }}>
                <div className="col-lg-6 col-11 d-flex justify-content-center align-items-center flex-column">
                    <Image draggable="false" src={Contact} className="pt-3" style={{ height: 'auto', width: '23rem' }}></Image>
                    <div className="d-flex flex-column justify-content-center align-items-lg-start align-items-center pt-4 p-0 container-fluid">
                        <div className="d-flex flex-row justify-content-center align-items-center">
                            <span className="fa fa-phone pl-4"></span>
                            <p className="p-2 m-0 font-weight-light" style={{ fontSize: '1.2em' }}><a style={{ textDecoration: 'none', color: 'black' }} href="tel:+91 9961024500">+91 9961024500</a></p>
                        </div>
                        <div className="d-flex flex-row justify-content-center align-items-center">
                            <span className="fa fa-envelope pl-4"></span>
                            <p className="p-2 m-0 font-weight-light" style={{ fontSize: '1.2em' }}><a style={{ textDecoration: 'none', color: 'black' }} href="mailto: support@stuidapp.com">support@stuidapp.com</a></p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-12 d-flex flex-column justify-content-center align-items-center py-4">
                    <div className="d-flex flex-column align-items-center justify-content-center pb-4 pt-4 container col-lg-9 col-12">
                        <p style={{ fontSize: '1em' }} className="text-capitalize font-weight-light text-center">Feel free to get in touch with us. We are happy to support you and answer to your queries.</p>
                    </div>
                    <Form className="container col-lg-9 col-12 m-0 p-0" method="post" action={process.env.PUBLIC_URL + "/contact.php"} >
                        <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Control type="text" placeholder="Name" name="contactname" />
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Control type="email" placeholder="You Email ID" name="contactemail" />
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Control type="tel" placeholder="Phone" name="contactphone" />
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Control as="textarea" className="inputID" placeholder="Message" name="contactmessage" rows={3} />
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlTextarea1" className="d-flex justify-content-end">
                            <Button className="rounded-pill pl-4 pr-4" variant="primary" type="submit">Send</Button>
                        </Form.Group>
                    </Form>
                </div>
            </div>
        </section>
    )
}
export default ContactUs;

