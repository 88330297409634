import React from 'react';
import Footer from '../Components/Footer';
import Subject from '../Components/Subject';
import ClassVideo from '../Components/ClassVideo';
import Demo from '../Components/Demo';
import Mathematics from '../img/venn-diagram.svg';
import Botany from '../img/botanist.svg';
import Physics from '../img/atom.svg';
import Zoology from '../img/ladybug.svg';
import Chemistry from '../img/flask.svg';

function Plus1() {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="d-flex flex-column justify-content-center align-items-center py-5" style={{ backgroundColor: '#f2f2f2', scrollMarginTop: '5rem' }}>
            <div className="d-flex flex-column justify-content-center align-items-center container-fluid col-10 text-center mt-4 pt-3" >
                <h1 className="font-weight-light text-capitalize text-center p-0 m-0" style={{ fontSize: '1.2em' }}>Kerala Board</h1>
                <h1 className="font-weight-bolder text-center p-0 m-0" style={{ fontSize: '4.2em' }}><span className="textColor">11th </span>Standard</h1>
                <small className="pt-3">The study notes, the animated video, audio lessons are designed and conceptualized by experts
                complying with the standards of KB syllabus catering to the learning objectives.</small>
            </div>
            <div className="container-fluid d-flex flex-lg-row flex-column justify-content-center align-items-center pt-3">
                <div className="d-flex flex-row">
                    <Subject bgcolor="bg-color" image={Mathematics} subject="Mathematics"></Subject>
                    <Subject bgcolor="bg-color1" image={Botany} subject="Botany"></Subject>
                </div>
                <div className="d-flex flex-row">
                    <Subject bgcolor="bg-color2" image={Zoology} subject="Zoology"></Subject>
                    <Subject bgcolor="bg-color3" image={Physics} subject="Physics"></Subject>
                </div>
                <Subject bgcolor="bg-color4" image={Chemistry} subject="Chemistry"></Subject>
            </div>
            <ClassVideo video1="https://vimeo.com/561840501" video2="https://vimeo.com/561840501"></ClassVideo>
            <Demo></Demo>
            <Footer></Footer>
        </div>
    )
}
export default Plus1;