import React from 'react';
import { Image } from 'react-bootstrap';
import VideoLesson from '../img/videoLessons.svg';
import OfflineVideo from '../img/OfflineVideo.svg';


function AskFeatures(props) {
    return (
        <section className="d-flex justify-content-center align-items-center flex-column py-5" style={{ width: '100vw', backgroundColor: '#f2f2f2' }}>
            {/* <Image draggable="false" src={blob} className="img2"></Image> */}
            <div className="container2 container-fluid d-flex justify-content-center align-items-center flex-column" style={{ width: '100vw' }}>
                <div className="d-flex flex-column align-items-center justify-content-center container mb-4 pt-4 col-8" style={{ paddingLeft: '3vw', paddingRight: '3vw' }}>
                    <h1 className="font-weight-bolder m-0 mb-3 text-center " style={{ fontSize: '4.2em' }}> <span className="textColor">{props.title}</span></h1>
                    <h3 className="m-0 p-0 text-center font-weight-lighter text-capitalize mb-2" style={{ fontSize: '1.2em' }}>Video lessons, audio lessons, offline video and study materials of the concepts</h3>
                </div>
                <div className="col-12 p-0 m-0 col-md-9 d-flex flex-column container-fluid justify-content-center align-items-center">
                    <div className="d-flex flex-lg-row flex-column col-lg-10 col-11 justify-content-center align-items-center">
                        <div className="learnFeatures col-lg-8 col-12 py-5 bg-white m-4 shadow-sm d-flex flex-column justify-content-center align-items-center pr-4 pl-4"  style={{borderRadius: '1.4rem' }}>
                            <Image draggable="false" src={VideoLesson} style={{ width: '12rem', height: 'auto' }}></Image>
                            <h4 className="font-weight-bolder pt-4">Ask your Doubt</h4>
                            <small className="text-center pt-3 text-black">Students can ask their doubts and get it clarified with their teachers. Teachers are available
                            during the office timings</small>
                        </div>
                        <div className="learnFeatures col-lg-8 col-12 py-5 bg-white m-4 shadow-sm d-flex flex-column justify-content-center align-items-center pr-4 pl-4"  style={{borderRadius: '1.4rem' }}>
                            <Image draggable="false" src={OfflineVideo} style={{ width: '12rem', height: 'auto' }}></Image>
                            <h4 className="font-weight-bolder pt-4">Post your questions</h4>
                            <small className="text-center pt-3 text-black">Students can post their questions in the space provided . The answers will be posted in response to the question</small>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default AskFeatures;