import React from 'react';
import '../App.css';
import { Navbar, Nav, Image,/* Modal */ } from 'react-bootstrap';
import { IndexLinkContainer } from 'react-router-bootstrap';
import { HashLink } from 'react-router-hash-link';
import Logo from '../img/newlogo.png';
import Phone from '../img/phone-call.png';
/*import Ad from '../img/open-image-2.jpg'; */
import { useLocation } from 'react-router-dom'





function Header(props) {
    const [expanded, setExpanded] = React.useState(false);

    const [show, setShow] = React.useState(true);
    console.log("the header props are ", props);
    let location = useLocation();
    React.useEffect(() => {
        console.log('the location is ', location.pathname);
        if (location.pathname !== "/") {
            setShow(false)
        }
    }, [location])

    return (
        <Navbar expanded={expanded} collapseOnSelect expand="lg" fixed="top" id="Navbar">
            {/*
			
           <Modal show={show} size="md" animation={true} centered aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Body className="p-0">
                    <span style={{ position: 'absolute', right: 10, left: 'auto', fontSize: 30, top: -9, bottom: 'auto' }}><i onClick={() => setShow(false)} className="fa fa-times text-white" style={{ cursor: 'pointer', fontSize: '.8em' }} aria-hidden="true"></i></span>
                    <Image src={Ad} fluid />
                </Modal.Body>
            </Modal>
            
			
			*/}
            <IndexLinkContainer to="/" >
                <Navbar.Brand onClick={() => window.location.href = "/"} >
                    <Image src={Logo} className="ml-4" style={{ width: '6rem', height: 'auto' }}></Image>
                </Navbar.Brand>
            </IndexLinkContainer>
            <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="ml-auto">
                    <HashLink onClick={() => setExpanded(false)} smooth to="/#Landing" className="text-left container-fluid mr-4 text-capitalize pt-2 pb-2" style={{ fontSize: '1.1em', borderBottomWidth: 3, color: 'black', fontWeight: 'bold' }}>Home</HashLink>
					<HashLink onClick={() => setExpanded(false)} smooth to="/Blog" className="text-left container-fluid mr-4 text-capitalize pt-2 pb-2" style={{ fontSize: '1.1em', borderBottomWidth: 3, color: 'black', fontWeight: 'bold' }}>Blog</HashLink>
					<HashLink onClick={() => setExpanded(false)} smooth to="/Learning#learn" className="text-left container-fluid mr-4 text-capitalize pt-2 pb-2" style={{ fontSize: '1.1em', color: 'black', fontWeight: 'bold' }}>Learn</HashLink>
                    <HashLink onClick={() => setExpanded(false)} smooth to="/Ask" className="text-left container-fluid mr-4 text-capitalize pt-2 pb-2" style={{ fontSize: '1.1em', color: 'black', fontWeight: 'bold' }}>ask</HashLink>
                    <HashLink onClick={() => setExpanded(false)} smooth to="/Test" className="text-left container-fluid mr-4 text-capitalize pt-2 pb-2" style={{ fontSize: '1.1em', color: 'black', fontWeight: 'bold' }}>test</HashLink>
                    <HashLink onClick={() => setExpanded(false)} smooth to="/Learning#Classes" className="text-left container-fluid mr-4 text-capitalize pt-2 pb-2" style={{ fontSize: '1.1em', color: 'black', fontWeight: 'bold' }}>Classes</HashLink>
                    <HashLink onClick={() => setExpanded(false)} smooth to="/#Demo" className="text-left container-fluid mr-4 text-capitalize pt-2 pb-2" style={{ fontSize: '1.1em', color: 'black', fontWeight: 'bold' }}>Demo</HashLink>
                    <HashLink onClick={() => setExpanded(false)} smooth to="careers" className="text-left container-fluid mr-4 text-capitalize pt-2 pb-2" style={{ fontSize: '1.1em', borderBottomWidth: 3, color: 'black', fontWeight: 'bold' }}>Careers</HashLink>
					<HashLink onClick={() => setExpanded(false)} smooth to="/#ContactUs" className="text-left container-fluid mr-4 text-capitalize pt-2 pb-2" style={{ fontSize: '1.1em', color: 'black', fontWeight: 'bold' }}>Contact</HashLink>
					<HashLink onClick={() => setExpanded(false)} smooth to="/Support" className="text-left container-fluid mr-4 text-capitalize pt-2 pb-2" style={{ fontSize: '1.1em', borderBottomWidth: 3, color: 'black', fontWeight: 'bold' }}>Support</HashLink>
					<HashLink onClick={() => setExpanded(false)} smooth to="/Partners" className="text-left container-fluid mr-4 text-capitalize pt-2 pb-2" style={{ fontSize: '1.1em', borderBottomWidth: 3, color: 'black', fontWeight: 'bold' }}>Partners</HashLink>
					{/* <HashLink to="/terms" className="font-weight-bolder mr-4 text-uppercase pt-2 pb-2" style={{ fontSize: '1.1em' }}>T&C</HashLink> */}
                    {/* <HashLink smooth to="/#ContactUs" className="font-weight-bolder mr-4 text-uppercase pt-2 pb-2" style={{ fontSize: '1.1em' }}>Contact Us</HashLink> */}
                    <div className="d-flex flex-row ml-3 align-self-start pr-4 rounded-pill justify-content-center align-items-center">
                        <Image draggable="false" src={Phone} style={{ height: '2rem', width: '2rem', }}></Image>
                        <div className="d-flex flex-column ml-3">
                            <span style={{ color: '#2a6edd' }}>Contact Us</span>
                            <span style={{ cursor: 'pointer' }} className="font-weight-bolder" style={{ fontSize: '1.4em' }}><a style={{ textDecoration: 'none', color: '#2a6edd' }} id="contactUs" href="tel:+919961024500">9961024500</a></span>
                        </div>
                    </div>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}
export default Header;