import React from 'react';
import ReactPlayer from 'react-player';

function ClassVideo(props) {
    return (
        <section className="pb-4" style={{ width: '100vw' }}>
            <div className="d-flex flex-column">
                <div className="container-fluid justify-content-center align-items-center d-flex flex-column pt-4" style={{ width: '90vw' }}>
                    <h1 className="font-weight-bolder text-center p-0 m-0 text-capitalize" style={{ fontSize: '3em' }}>Demo Videos</h1>
                </div>
                <div className="container-fluid d-flex flex-lg-row flex-column justify-content-around align-items-center position-relative" style={{ width: '80vw' }}>
                    {/* <Image draggable="false" src={pattern} style={{ height: 'auto', width: '7rem', right: '22vw', marginTop: '20vh', position: 'absolute', opacity: '0.1' }}></Image> */}
                    <div className="d-flex justify-content-center align-items-center m-3" style={{ height: 'auto', width: '100vw', borderRadius: '20px', zIndex: 10, overflow: 'hidden' }}>
                        <ReactPlayer controls={true} playing={false} loop={true} url={props.video1} />
                    </div>
                    <div className="d-flex justify-content-center align-items-center m-3" id="classVideo" style={{ height: 'auto', width: '100vw', borderRadius: '20px', zIndex: 10, overflow: 'hidden' }}>
                        <ReactPlayer controls={true} playing={false} loop={true} url={props.video2} />
                    </div>
                    {/* <Image draggable="false" src={pattern} style={{ height: 'auto', width: '7rem', position: 'absolute', opacity: '0.1', top:'3rem', bottom: 'auto',right: 'auto', left:'auto', zIndex:999}}></Image> */}
                </div>
            </div>
        </section>
    )
}
export default ClassVideo;