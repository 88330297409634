import React from 'react';
import Footer from '../Components/Footer';


import { Image } from 'react-bootstrap';
import playStore from '../img/google-play-badge.webp';
import AppStore from '../img/App_store.svg';
import refer from '../img/refer1.png';
import refer2 from '../img/refer2.png';


function Terms() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
	
	
	<section id="Landing" className="d-flex justify-content-center align-items-center pt-5" style={{ width: '100vw' }}>
            <div className=" pt-lg-0 d-flex justify-content-center" style={{ width: '100vw' }}>
                <div className="d-flex flex-lg-row flex-column pt-5 col-10 align-items-center h-100">
                    <div className="col-lg-6 col-md-10 col-12 d-flex align-items-lg-start align-items-center justify-content-center flex-column py-5 px-0">
                        <h5 id="title" className="font-weight-bolder m-0 mb-3 text-lg-left text-center" style={{ fontSize: '4em' }}>Refer & <span className="textColor"> Earn</span></h5>
                       <p>Stuid Learning App incorporates an outstanding feature that may benefit you and your friends.<br></br>So, what is Refer & Earn? How does it work?<br></br> Ok, let’s see.</p>
                    </div>
                    <div className="col-lg-6 col-12 d-flex justify-content-center align-items-center">
                        <Image draggable="false" src={refer} id="landingPageimg" style={{ height: 'auto', width: '35em' }}></Image>
                    </div>
                </div>
            </div>
        </section>
            <div className=" pt-lg-0 d-flex justify-content-center" style={{ width: '100vw' }}>
                <div className="d-flex flex-lg-row flex-column pt-5 col-10 align-items-center h-100">
                    <div className="col-lg-6 col-md-10 col-12 d-flex align-items-lg-start align-items-center justify-content-center flex-column py-5 px-0">
	<h3>How to select Refer & Earn option?</h3>
<ul>
<li>download Stuid Learning App from the play store.</li>
<li>Log in to our app and then select the “profile” option at the bottom right corner of the app. </li>
<li>There, Select the Refer & Earn option </li>
</ul>
<h4>How does Refer & Earn work?</h4>
<ul>
<li>After selecting Refer & Earn option, you can see a share button</li>
<li>Press on the share button </li>
<li>Hence, using your referral code, you can share our app’s link to your friends through any of the social media platforms you like. </li>
<li>The rest depends on your friends.</li>
<li>Your friends have to download our app through the link you sent.</li>
<li>After that, they have to log in to the Stuid Learning App and the moment any one of them logs in to our app, you will be credited with Rs 100 on your Stuid app’s wallet.</li>
<li>If more friends log in to our app using your referral code, you can earn more in your Stuid app’s wallet.</li>
<li>Your friends can also earn in a similar way.</li>	
</ul>
	<h6>Advantages of using Refer & Earn:</h6>
	<ul>
		<li>The advantage of this process is that using the amount in your Stuid app’s wallet,you can purchase any course from our app.</li>
		<li>Another benefit of this process is that if the amount required to purchase a course is more than the amount you have in your wallet, never mind you can still use the amount in your wallet and can pay the rest of the amount according to your plan.</li>
	</ul>
	<h6>View your rewards:</h6>
	<ul>
		<li>To view your rewards, select the menu option at the top left corner of our app</li>
		<li>There you can see “wallet” option</li>
		<li>Select the wallet option and you can view your rewards</li>
	</ul>
<p>In a nutshell, this process will surely benefit you. That is a good idea, right? Then, why are you
waiting? Refer & Earn, earn more and more, purchase maximum courses, and finally attain
exceptional knowledge.</p>
	                    </div>
                    <div className="col-lg-6 col-12 d-flex justify-content-center align-items-center">
                        <Image draggable="false" src={refer2} id="landingPageimg" style={{ height: 'auto', width: '35em' }}></Image>
                    </div>
                </div>
				
            </div>

      <Footer></Footer>
    </>
  )
} export default Terms;