import React from 'react';
import '../App.css';
import { Image, Collapse } from 'react-bootstrap';
import Learn from '../img/book (4).png';
import Test from '../img/test.svg';
import Practice from '../img/gear.svg';
import Monitor from '../img/pie-chart.svg';
import Managment from '../img/project-management.svg';
import Analyze from '../img/evaluation.svg';
import Video from '../img/hbphone.mp4'


function Features(props) {
  const [ShowText, setShowText] = React.useState();

  return (
    <section id="Features" className="d-flex justify-content-center align-items-center flex-column" style={{ width: '100vw' }}>
      {/* <Image draggable="false" src={blob} className="img2"></Image> */}
      <div className="container2 d-flex align-items-center flex-column" style={{ width: '100vw' }}>
        <div className="col-md-9 col-10 d-flex flex-column justify-content-center align-items-center ml-4 mr-4 px-lg-4" style={{ marginTop: '5rem' }}>
          <h3 className="font-weight-light text-capitalize text-center p-0 m-0" style={{ fontSize: '1.2em' }}>{props.subHead}</h3>
          <h2 className="font-weight-bolder text-center p-0 m-0" style={{ fontSize: '4.2em' }}>Why <span className="textColor">Stuid Learning App</span></h2>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center" style={{ width: '100vw' }}>
          {/* <div className="container-fluid" style={{ height: '70%' }}></div>
            <div className="container-fluid" style={{ height: '30%', backgroundColor: 'black' }}></div> */}
          <div className="col-9 col-lg-10  d-flex flex-lg-row flex-column align-items-center justify-content-center py-4 px-0">
            {/* box1 */}
            <div className="d-flex flex-column justify-content-start align-items-center container-fluid p-0">
              {/* <Image src={blob} className="img3-1"></Image> */}
              <div className="feature_box1 mb-2 shadow d-flex flex-row justify-content-start align-items-center pt-3 pb-3 col-12 px-4" style={{ backgroundColor: '#fff', borderRadius: '4px', paddingLeft: '2vw', paddingRight: '2vw' }}>
                {/* <span className="font-weight-bolder ml-2" style={{ fontSize: '2em', opacity: '0.6' }}>01</span> */}
                <Image draggable="false" src={Learn} style={{ height: 'auto', width: '2rem' }}></Image>
                <div className="d-flex flex-column justify-content-center align-items-start">
                  <h3 className="text-capitalize m-0 pl-4 font-weight-bolder pb-2" style={{ fontSize: '1.5em' }}>Learn</h3>
                  <small className="text-capitalize m-0 pl-4 " style={{ fontSize: '1em', lineHeight: '1.5em' }}>Audio, video, and textual presentation of the concepts.</small>
                  <Collapse in={ShowText === 1 ? true : false}>
                    <small className="text-capitalize m-0 pl-4" style={{ fontSize: '1em', lineHeight: '1.5em' }}>Gain knowledge and proficiency by studying, practicing the subjects and various concepts in depth Audio and visual presentation of concepts has an impact on the memory and eases the processing of learning new or complicated theories .</small>
                  </Collapse>
                  {
                    ShowText !== 1 ? <h4 onClick={() => setShowText(1)} className="text-capitalize m-0 pl-4 font-weight-bold text-primary pt-2" style={{ fontSize: '1em', cursor: 'pointer' }}>Read More</h4> : <h4 onClick={() => setShowText()} className="text-capitalize m-0 pl-4 font-weight-bold text-primary pt-2" style={{ fontSize: '1em', cursor: 'pointer' }}>Show less</h4>
                  }
                </div>
              </div>
              <div className="feature_box1 mb-2 shadow d-flex flex-row justify-content-start align-items-center pt-3 pb-3 col-12 px-4" style={{ backgroundColor: '#fff', borderRadius: '4px', paddingLeft: '2vw', paddingRight: '2vw' }}>
                <Image draggable="false" src={Test} style={{ height: 'auto', width: '2rem' }}></Image>
                <div className="d-flex flex-column justify-content-center align-items-start">
                  <h3 className="text-capitalize m-0 pl-4 font-weight-bolder pb-2" style={{ fontSize: '1.5em' }}>Test</h3>
                  <h4 className="text-capitalize m-0 pl-4 font-weight-normal" style={{ fontSize: '1em', lineHeight: '1.5em' }}>Evaluate what is learned with test series</h4>
                  <Collapse in={ShowText === 2 ? true : false}>
                    <h5 className="text-capitalize m-0 pl-4 font-weight-normal" style={{ fontSize: '1em', lineHeight: '1.5em' }}>Tests are the part of learning and let the students know what they excel at and the areas they can concentrate on. It will help the student to focus more on the places where there is weakness changing it to strengths.</h5>
                  </Collapse>
                  {
                    ShowText !== 2 ? <h4 onClick={() => setShowText(2)} className="text-capitalize m-0 pl-4 font-weight-bold text-primary pt-2" style={{ fontSize: '1em', cursor: 'pointer' }}>Read More</h4> : <h4 onClick={() => setShowText()} className="text-capitalize m-0 pl-4 font-weight-bold text-primary pt-2" style={{ fontSize: '1em', cursor: 'pointer' }}>Show less</h4>
                  }
                </div>
              </div>
              <div className="feature_box1 mb-2 shadow d-flex flex-row justify-content-start align-items-center pt-3 pb-3 col-12 px-4" style={{ backgroundColor: '#fff', borderRadius: '4px', paddingLeft: '2vw', paddingRight: '2vw' }}>
                <Image draggable="false" src={Practice} style={{ height: 'auto', width: '2rem' }}></Image>
                <div className="d-flex flex-column justify-content-center align-items-start">
                  <h3 className="text-capitalize m-0 pl-4 font-weight-bolder pb-2" style={{ fontSize: '1.5em' }}>Practice</h3>
                  <h4 className="text-capitalize m-0 pl-4 font-weight-normal" style={{ fontSize: '1em', lineHeight: '1.5em' }}>Practice to be perfect with chapter wise tests that are customized to cater to the needs of every student.</h4>
                  <Collapse in={ShowText === 3 ? true : false}>
                    <h4 className="text-capitalize m-0 pl-4 font-weight-normal" style={{ fontSize: '1em', lineHeight: '1.5em' }}>Practice makes a man perfect as we all know. There can be no alternate for hard work. Students have practice sessions in Stuid learning app with is an accomplishment towards proper planning and regular practice which takes a step forward to making a perfect performance and the best person.</h4>
                  </Collapse>
                  {
                    ShowText !== 3 ? <h4 onClick={() => setShowText(3)} className="text-capitalize m-0 pl-4 font-weight-bold text-primary pt-2" style={{ fontSize: '1em', cursor: 'pointer' }}>Read More</h4> : <h4 onClick={() => setShowText()} className="text-capitalize m-0 pl-4 font-weight-bold text-primary pt-2" style={{ fontSize: '1em', cursor: 'pointer' }}>Show less</h4>
                  }
                </div>
              </div>
            </div>
            {/* box2 */}
            {/* <Image draggable="false" src={mockup} className="col-lg-4" style={{ width: '20rem', height: 'auto' }}></Image> */}
            <video width="auto" id="video" height="600" controls={false} autoPlay={true} loop={true}>
              <source src={Video} type="video/mp4" />
            </video>
            {/* box3 */}
            <div className="d-flex flex-column justify-content-start align-items-center container-fluid p-0">
              <div className="feature_box1 mb-2 shadow d-flex flex-row justify-content-start align-items-center py-3 col-12 px-4" style={{ backgroundColor: '#fff', borderRadius: '4px', paddingLeft: '2vw', paddingRight: '2vw' }}>
                <Image draggable="false" src={Monitor} style={{ height: 'auto', width: '2rem' }}></Image>
                <div className="d-flex flex-column justify-content-center align-items-start">
                  <h3 className="text-capitalize m-0 pl-4 font-weight-bolder pb-2" style={{ fontSize: '1.5em' }}>Monitor</h3>
                  <h4 className="text-capitalize m-0 pl-4 font-weight-normal" style={{ fontSize: '1em', lineHeight: '1.5em' }}>Parents can monitor the performance of their ward.</h4>
                  <Collapse in={ShowText === 4 ? true : false}>
                    <h4 className="text-capitalize m-0 pl-4 font-weight-normal" style={{ fontSize: '1em', lineHeight: '1.5em' }}>Nurturing your child isn’t an easy job with duties that appear to change each day.  There is one chore that is important: Monitoring your child's contact with their encompassing world. Parents should know about their children’s exercises and associations through each age and phase of development.</h4>
                  </Collapse>
                  {
                    ShowText !== 4 ? <h4 onClick={() => setShowText(4)} className="text-capitalize m-0 pl-4 font-weight-bold text-primary pt-2 py-2" style={{ fontSize: '1em', cursor: 'pointer' }}>Read More</h4> : <h4 onClick={() => setShowText()} className="text-capitalize m-0 pl-4 font-weight-bold text-primary pt-2" style={{ fontSize: '1em', cursor: 'pointer' }}>Show less</h4>
                  }
                </div>
              </div>
              <div className="feature_box1 mb-2 shadow d-flex flex-row justify-content-start align-items-center pt-3 pb-3 col-12 px-4" style={{ backgroundColor: '#fff', borderRadius: '4px', paddingLeft: '2vw', paddingRight: '2vw' }}>
                <Image draggable="false" src={Managment} style={{ height: 'auto', width: '2rem' }}></Image>
                <div className="d-flex flex-column justify-content-center align-items-start">
                  <h3 className="text-capitalize m-0 pl-4 font-weight-bolder pb-2" style={{ fontSize: '1.5em' }}>Reports</h3>
                  <p className="text-capitalize m-0 pl-4 font-weight-normal" style={{ fontSize: '1em', lineHeight: '1.5em' }}>Parent can analyze the performance report of their children.</p>
                  <Collapse in={ShowText === 5 ? true : false}>
                    <p className="text-capitalize m-0 pl-4 font-weight-normal" style={{ fontSize: '1em', lineHeight: '1.5em' }}>An effective report card with data about what a student knows and can show comparative with the graded curriculum and what the understudy needs to do next. The report card ought to recognize activities that should be taken by accomplices in learning – students, parents, and teachers. Reports will dissect the grade evolution of the student scored during each test. These exercises are positively related to accomplishment since they produce valuable data to tutors and understudies and on the grounds of their performance.</p>
                  </Collapse>
                  {
                    ShowText !== 5 ? <p onClick={() => setShowText(5)} className="text-capitalize m-0 pl-4 font-weight-bold text-primary pt-2" style={{ fontSize: '1em', cursor: 'pointer' }}>Read More</p> : <h3 onClick={() => setShowText()} className="text-capitalize m-0 pl-4 font-weight-bold text-primary pt-2" style={{ fontSize: '1em', cursor: 'pointer' }}>Show less</h3>
                  }
                </div>
              </div>
              <div className="feature_box1 mb-2 shadow d-flex flex-row justify-content-start align-items-center pt-3 pb-3 col-12 px-4" style={{ backgroundColor: '#fff', borderRadius: '4px', paddingLeft: '2vw', paddingRight: '2vw' }}>
                <Image draggable="false" src={Analyze} style={{ height: 'auto', width: '2rem' }}></Image>
                <div className="d-flex flex-column justify-content-center align-items-start">
                  <h3 className="text-capitalize m-0 pl-4 font-weight-bolder pb-2" style={{ fontSize: '1.5em' }}>Track</h3>
                  <h5 className="text-capitalize m-0 pl-4 font-weight-normal" style={{ fontSize: '1em', lineHeight: '1.5em' }}>Keep track of the learning progress of your child.</h5>
                  <Collapse in={ShowText === 6 ? true : false}>
                    <h3 className="text-capitalize m-0 pl-4 font-weight-normal" style={{ fontSize: '1em', lineHeight: '1.5em' }}>As a parent, you are the link between your child and the rest of the world. With Stuid learning app, track your child’s grade, behavior boosts their confidence. Children whose parents are engaged with their education to improve reviews and have higher grades. Also, the more parents are involved, the more their children appear to yield better in terms of academia. Parents who are highly engaged in the educational process showed that their children were more likely to improve in reading and math.</h3>
                  </Collapse>
                  {
                    ShowText !== 6 ? <h4 onClick={() => setShowText(6)} className="text-capitalize m-0 pl-4 font-weight-bold text-primary pt-2" style={{ fontSize: '1em', cursor: 'pointer' }}>Read More</h4> : <h4 onClick={() => setShowText()} className="text-capitalize m-0 pl-4 font-weight-bold text-primary pt-2" style={{ fontSize: '1em', cursor: 'pointer' }}>Show less</h4>
                  }
                </div>
              </div>
              {/* <Image src={blob} className="img3-2"></Image> */}
            </div>

          </div>
        </div>
      </div>
    </section>
  )
}
export default Features;