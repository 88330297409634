import React from 'react';
import { Image, Modal, Form, Button } from 'react-bootstrap';
import '../App.css';
import mockup2 from '../img/boy.webp';
import phone from '../img/smartphone.svg';
import Tablet from '../img/tablet.svg';
import playStore from '../img/google-play-badge.webp';
import AppStore from '../img/App_store.svg';

function Demo() {
    const [show, setShow] = React.useState(false);

    const handleModal = () => show ? setShow(false) : setShow(true);

    return (
        <section id="Demo" className="d-flex flex-column align-items-center pt-5" style={{ width: '100vw', backgroundColor: '#282728' }}>
            <Modal show={show}
                animation={true}
                centered
                // dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title" onHide={handleModal}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h3 className="m-0">Start your journey with Stuid learning app</h3>
                        <small>Fill the below form and we will reach you in no time!</small>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form method="post" action={process.env.PUBLIC_URL + "/demo.php"}>
                        <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Your Name</Form.Label>
                            <Form.Control type="text" placeholder="Name" name="contactName" required />
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control type="email" placeholder="Email" name="contactEmail" required />
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Contact Number</Form.Label>
                            <Form.Control type="tel" placeholder="Number" name="contactPhone" required />
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlSelect1" >
                            <Form.Label>Our Classes</Form.Label>
                            <Form.Control as="select" name="contactClass" required >
                                <option>8th Standard</option>
                                <option>9th Standard</option>
                                <option>10th Standard</option>
                                <option>11th Standard</option>
                                <option>12th Standard</option>
                                {/* <option>PSC</option> */}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlTextarea1"  >
                            <Form.Label>Your Message</Form.Label>
                            <Form.Control as="textarea" rows={3} name="contactMessage" required />
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Button className="col-12 rounded-pill font-weight-bolder" variant="primary" type="submit">Send</Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                {/* <Modal.Footer className="col-12">
                    <Button className="col-12 rounded-pill mt-n4 font-weight-bolder" onClick={handleModal}>Close</Button>
                </Modal.Footer> */}
            </Modal>
            <div className="d-flex flex-column justify-content-center align-items-center col-10 col-md-8" style={{ paddingBlock: '5vh', width: '90vw' }}>
                <p className="text-white font-weight-lighter mb-4 m-0 pt-5 text-lg-left text-center" style={{ fontSize: '1.2em' }}>One step ahead Be a step ahead</p>
                <p className="m-0 text-white text-center font-weight-bolder demo_text pb-2" style={{ fontSize: '3em' }}>Stuid learning app is the right choice for all your needs</p>
            </div>
            <div className="d-flex flex-row justify-content-around align-items-center">
                <div className="d-flex flex-column justify-content-center align-items-center m-3">
                    <Image draggable="false" src={phone} style={{ height: 'auto', width: '1.6rem' }}></Image>
                    <p className="text-white pt-3 m-0" style={{ fontSize: '1.2em' }}>Mobile</p>
                    <p className="font-weight-lighter text-white m-0 pt-1" style={{ fontSize: '0.9em' }}>iOS & Android</p>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center m-3">
                    <Image draggable="false" src={Tablet} style={{ height: 'auto', width: '1.6rem' }}></Image>
                    <p className="text-white pt-3 m-0" style={{ fontSize: '1.2em' }}>Tablets</p>
                    <p className="font-weight-lighter text-white m-0 pt-1" style={{ fontSize: '0.9em' }}>Android & iPad</p>
                </div>
            </div>
            {/* <div className="ml-4 mr-4">
                <Form>
                    <div className="d-flex flex-row">
                        <Form.Control className="mr-2" placeholder="Name" />
                        <Form.Control placeholder="Phone Number" />
                        <Form.Control
                            as="select"
                            className="my-1 mr-sm-2"
                            id="inlineFormCustomSelectPref"
                            custom
                            className="ml-2"
                        >
                            <option value="0">Class</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </Form.Control>
                    </div>
                    <div className="pt-3 d-flex flex-row">
                        <Form.Control placeholder="Address" className="col-8 mr-1" />
                        <Button className="col-4" style={{ fontSize: '.8em' }} variant="primary" type="submit">
                            BOOK DEMO
              </Button>
                    </div>
                </Form>
            </div> */}
            <div className="col-lg-10 col-10 col-md-8 platformbox d-flex flex-column justify-content-center align-items-end flex-lg-row-reverse pt-lg-4 my-5 bg-danger" style={{ borderRadius: '1em' }}>
                <div className="col-12 col-lg-6 d-flex flex-column align-items-center align-items-lg-start justify-content-center pb-xl-5 align-self-center" style={{ paddingLeft: '7vw', paddingRight: '7vw' }}>
                    <p className="text-white font-weight-lighter mb-4 m-0 pt-5 text-lg-left text-center" style={{ fontSize: '1.2em' }}>Get started with a free trial now.</p>
                    <h5 className="font-weight-bolder text-white text-capitalize m-0 pb-4 text-lg-left text-center" style={{ fontSize: '4em' }}>Book a free counselling session and demo</h5>
                    {/* <h4 className="font-weight-lighter text-white pt-3 m-0" style={{ fontSize: '1.5em' }}>A user interface designed for the classroom</h4> */}
                    <div style={{ cursor: 'pointer' }} className="bg-white rounded-pill shadow-sm d-flex justify-content-center align-items-center" onClick={handleModal}>
                        <h4 className="text-capitalize font-weight-bold m-0 p-3 pl-4 pr-4 text-primary" style={{ fontSize: '1.2em' }}>BOOK DEMO<i className="fa fa-arrow-circle-o-down ml-2"></i></h4>
                    </div>
                    {/* <span className="d-flex flex-row align-items-center mt-4">
                        <h4 className="font-weight-bolder text-white m-0 p-0">Get the app</h4>
                        <span className="fa fa-arrow-circle-o-right text-white ml-3"></span>
                    </span> */}
                </div>
                <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center overflow-hidden">
                    <Image draggable="false" src={mockup2} id="landingPageimg1" style={{ height: 'auto', width: '30rem' }}></Image>
                </div>
            </div>
            <div className="d-flex flex-row">
                <div style={{ cursor: 'pointer' }}>
                    <Image src={playStore} onClick={() => window.open("https://play.google.com/store/apps/details?id=com.stuid.main")} className="m-1" style={{ height: 'auto', width: '9rem' }}></Image>
                </div>
                <div style={{ cursor: 'pointer' }}>
                    <Image src={AppStore} onClick={() => window.open("https://apps.apple.com/in/app/stuid-learning-app/id1575872556")} className="m-1" style={{ height: 'auto', width: '8rem' }}></Image>
                </div>
            </div>
        </section>
    )
}
export default Demo;