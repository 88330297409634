import React from 'react';
import { Image } from 'react-bootstrap';
import '../App.css';
import t1 from '../img/testi1.png';
import t2 from '../img/testi2.png';
import t3 from '../img/testi3.png';
import Quote from '../img/left-quotes-sign.svg';

function testimonials() {
    return (
        <div className="d-flex flex-column justify-content-center align-items-center" style={{backgroundColor:"#f2f2f2"}}>
            <div className="intro mt-4 pt-4 mb-4 col-10 col-md-8">
                <h4 className="m-0 p-0 text-center font-weight-lighter text-capitalize mb-2" style={{ fontSize: '1.2em' }}>testimonials</h4>
                <h2 className="font-weight-bolder text-center p-0 m-0 text-capitalize" style={{ fontSize: '3em' }}>How our <span className="textColor"> students</span>  feel about us </h2>
            </div>
            <div className="col-10 d-flex flex-lg-row flex-column justify-content-center allign-items-center my-4 pb-5">
                <div className="testimonialbox d-flex flex-column justify-content-center align-items-center bg-white shadow col-12 col-md-11 col-lg-4 py-3 my-2 mx-lg-2 rounded">
                    <Image draggable="false" src={Quote} className="mt-4" style={{ height: 'auto', width: '1.5rem' }}></Image>
                    <h4 className="text-center mt-3 px-3 font-weight-light" style={{ fontSize: '1.1em', lineHeight: '1.6em'}}>Rasheed is a student from class 8 and he feels that Stuid learning app has made him fall in love with learning quite literally. He is truly thankful that he can utilize the app anytime and anywhere without any constraints.</h4>
                    <div className="my-2" style={{ width: '3vw', height: '.3vh', backgroundColor: 'gray' }}></div>
                    <div className="d-flex justify-content-center align-items-center overflow-hidden my-2" style={{ borderRadius: '50rem', backgroundColor:'#fff'}}>
                        <Image draggable="false" src={t1} style={{ height: '3rem', width: '3rem'}}></Image>
                    </div>
                    <h4 style={{ fontSize: '1.2em' }}>Rasheed</h4>
                </div>
                <div className="testimonialbox d-flex flex-column justify-content-center align-items-center bg-white shadow col-12 col-lg-4 col-md-11 py-3 my-2 mx-lg-2 rounded">
                    <Image draggable="false" src={Quote} className="mt-4" style={{ height: 'auto', width: '1.5rem' }}></Image>
                    <h4 className="text-center mt-3 px-3 font-weight-light" style={{ fontSize: '1.1em', lineHeight: '1.6em'}}>Ambika of class 9 says that Stuid learning app has totally changed her life in the meantime. Now she can get her doubts immediately clarified by educators of Stuid learning app. Earlier she used to battle a lot, particularly in Math and Science. Yet, subsequent to watching the videos of Stuid learning app, she was not only able to understand every concept, but also she began scoring better marks.</h4>
                    <div className="my-2" style={{ width: '3vw', height: '.3vh', backgroundColor: 'gray' }}></div>
                    <div className="d-flex justify-content-center align-items-center overflow-hidden my-2" style={{ borderRadius: '50rem', backgroundColor:'#fff'}}>
                        <Image draggable="false" src={t2} style={{ height: '3rem', width: '3rem' }}></Image>
                    </div>
                    <h4 style={{ fontSize: '1.2em' }}>Ambika</h4>
                </div>
                <div className="testimonialbox d-flex flex-column justify-content-center align-items-center bg-white shadow col-12 col-lg-4 col-md-11 py-3 my-2 mx-lg-2 rounded">
                    <Image draggable="false" src={Quote} className="mt-4" style={{ height: 'auto', width: '1.5rem' }}></Image>
                    <h4 className="text-center mt-3 px-3 font-weight-light" style={{ fontSize: '1.1em', lineHeight: '1.6em'}}>Kanmani is class ten loves the visualization method for educating at Stuid learning app. Presently when he contemplates subjects like Math, he can envision and see the pragmatic use of every formula and theorem. He says that now he studies only through Stuid learning app and he can perform astoundingly in tests every time.</h4>
                    <div className="my-2" style={{ width: '3vw', height: '.3vh', backgroundColor: 'gray' }}></div>
                    <div className="d-flex justify-content-center align-items-center overflow-hidden my-2" style={{ borderRadius: '50rem', backgroundColor:'#fff'}}>
                        <Image draggable="false" src={t3} style={{ height: '3rem', width: '3rem' }}></Image>
                    </div>
                    <h4 style={{ fontSize: '1.2em' }}>Kanmani</h4>
                </div>
            </div>
            {/* <div className="intro mt-4 pt-4 mb-4 col-10 d-flex justify-content-center align-items-center flex-column">
                <h1 className="font-weight-bolder text-center p-0 m-0 text-capitalize" style={{ fontSize: '3em' }}><span className="textColor">Lorem ipsum</span> consectetur</h1>
                <small className="col-lg-5 col-9 py-3 m-0 p-0 text-center font-weight-lighter text-capitalize mb-2" style={{ fontSize: '1.2em' }}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
                            molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum</small>
                <h1 className="font-weight-bolder text-center p-0 m-0 text-uppercase py-4 my-4" style={{ fontSize: '3em' }}><span className="textColor">join now<i className="fa fa-long-arrow-right ml-2"></i></span></h1>
            </div> */}

        </div>
    )
}
export default testimonials;