import React from 'react';
import { Image } from 'react-bootstrap';
import playStore from '../img/google-play-badge.webp';
import AppStore from '../img/App_store.svg';
import boy from '../img/GIRL_website.png';

function LandingPage() {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <section id="Landing" className="d-flex justify-content-center align-items-center pt-5" style={{ width: '100vw' }}>
            <div className=" pt-lg-0 d-flex justify-content-center" style={{ width: '100vw' }}>
                <div className="d-flex flex-lg-row flex-column pt-5 col-10 align-items-center h-100">
                    <div className="col-lg-6 col-md-10 col-12 d-flex align-items-lg-start align-items-center justify-content-center flex-column py-5 px-0">
                        <h5 id="title" className="font-weight-bolder m-0 mb-3 text-lg-left text-center" style={{ fontSize: '4em' }}>Learn using <span className="textColor"> Stuid App</span>: anytime, anywhere with any device</h5>
                        <div className="pt-3 d-flex flex-row">
                            <div style={{ cursor: 'pointer' }}><Image src={playStore} className="m-1" onClick={() => window.open("https://play.google.com/store/apps/details?id=com.stuid.main")} style={{ height: 'auto', width: '9rem' }} /></div>
                            <div style={{ cursor: 'pointer' }}><Image src={AppStore} className="m-1" onClick={() => window.open("https://apps.apple.com/in/app/stuid-learning-app/id1575872556")} style={{ height: 'auto', width: '8rem' }}></Image></div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 d-flex justify-content-center align-items-center">
                        <Image draggable="false" src={boy} id="landingPageimg" style={{ height: 'auto', width: '35em' }}></Image>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default LandingPage;