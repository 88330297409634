import React from 'react';
import '../App.css';
import Logo from '../img/newlogowhite.png';
import { Image } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';


function Footer() {
  // const [show, IsShow] = React.useState(false)
  return (
    <section className="container-fluid footer-container pb-0 mb-0 justify-content-center text-light" id="footer">
      <footer>
        <div className="row my-5 justify-content-center">
          <div className="col-10">
            <div className="row">
              <div className="col-xl-5 col-md-4 col-sm-5 col-12 p-0 m-0">
                <Image draggable="false" src={Logo} style={{ width: '10rem', height: 'auto' }}></Image>
              </div>
              {/*  */}
              <div className="d-flex justify-content-between col-xl-4 col-md-5 col-sm-4 col-12 p-0 m-0">
                <div className="col-xl-6 col-md-6 col-sm-6">
                  <p className="mb-3 mb-lg-4 bold-text text-white"><b>Our Apps</b></p>
                  <ul className="list-unstyled text-white">
                    <li><a href="https://play.google.com/store/apps/details?id=com.stuid.main" className="text-white">Stuid Learning App</a></li>
                  </ul>
                </div>
                <div className="col-xl-6 col-md-6 col-sm-6">
                  <p className="mb-3 mb-lg-4 bold-text text-white"><b>Other Links</b></p>
                  <ul className="list-unstyled text-white">
                    <li><a href="/#Features" className="text-white"><HashLink smooth to="/#Features" className="mr-4 pt-2 pb-2 text-white" style={{ fontSize: '1em' }}>Features</HashLink></a></li>
                    {/* <li><a href="#FAQ" className="text-white">FAQ</a></li> */} 
                    <li><a href="/Terms"><HashLink smooth to="/Terms" className="mr-4 pt-2 pb-2 text-white" style={{ fontSize: '1em', whiteSpace: 'nowrap' }}>Terms and Conditions</HashLink></a></li>
                    <li><a href="/Privacy"><HashLink smooth to="/Privacy" className="mr-4 pt-2 pb-2 text-white" style={{ fontSize: '1em' }}>Privacy Policy</HashLink></a></li>
                    <li><a href="/RefundPolicy"><HashLink smooth to="/RefundPolicy" className="mr-4 pt-2 pb-2 text-white" style={{ fontSize: '1em' }}>Refund Policy</HashLink></a></li>
                    <li><a href="/careers"><HashLink smooth to="/careers" className="mr-4 pt-2 pb-2 text-white" style={{ fontSize: '1em' }}>Careers</HashLink></a></li>
					<li><a href="/referandearn"><HashLink smooth to="/referandearn" className="mr-4 pt-2 pb-2 text-white" style={{ fontSize: '1em' }}>Refer & Earn</HashLink></a></li>
					{/* <li><a href="#"><HashLink smooth to="/Copyright" className="mr-4 pt-2 pb-2 text-white" style={{ fontSize: '1em' }}>Copyright Policy</HashLink></a></li> */}
                  </ul>
                </div>
              </div>
              {/*  */}
              <div className="col-xl-3 col-md-3 col-sm-3 col-12">
                <p className="mb-3 mb-lg-4  bold-text mt-sm-0 mt-5 text-white"><b>Contact Info</b></p>
                <div className="d-flex flex-row">
                  <span><i className="fa fa-map-marker mr-2" aria-hidden="true"></i></span>
                  <p className="mb-1 text-white">Stuid Learning App,1st Floor,<br></br>Thappy's Arcade,NH Bypass Road,Pantheerankavu,Calicut,Kerala<br></br> Pin: 673019</p>
                </div>
                <div className="d-flex flex-row justify-content-start align-items-center">
                  <span><i className="fa fa-envelope mr-2" aria-hidden="true"></i></span>
                  <p className="mb-1 text-white"><a style={{ textDecoration: 'none', color: 'white' }} href="mailto: contact@stuidapp.com">contact@stuidapp.com</a></p>
                </div>
                <div className="d-flex flex-row justify-content-start align-items-center">
                  <span><i className='bx bx-support mr-2'></i></span>
                  <p className="mb-1 text-white"><a style={{ textDecoration: 'none', color: 'white' }} href="mailto: support@stuidapp.com">support@stuidapp.com</a></p>
                </div>
                <div className="d-flex flex-row justify-content-start align-items-center">
                  <span><i className="fa fa-phone mr-2" aria-hidden="true"></i></span>
                  <p className="mb-1 text-white"><a style={{ textDecoration: 'none', color: 'white' }} href="tel:+91 9961024500">9961024500</a></p>
                </div>
              </div>
			  
            </div>
            <div className="row mt-3">
              <div className="col-xl-8 col-md-4 col-sm-4 col-auto my-md-0 mt-5 order-sm-1 order-3 align-self-end">
                <p className="social text-white mb-0 pb-0 bold-text"> <span className="mx-2"><a href="https://www.facebook.com/stuidApp/"><i className="fa fa-facebook" style={{ textDecoration: 'none', color: 'white' }} aria-hidden="true" /></a></span> <span className="mx-2"><a href="https://twitter.com/stuidapp"><i className="fa fa-twitter" style={{ textDecoration: 'none', color: 'white' }} aria-hidden="true" /></a></span> <span className="mx-2"><a href="https://www.instagram.com/stuidapp/"><i className="fa fa-instagram" style={{ textDecoration: 'none', color: 'white' }} aria-hidden="true" /></a></span> </p><small className="rights text-white">All Rights Reserved © 2020 Stuid Learning App LLP </small>
                             

			  </div>
            </div>

          </div>
        </div>
      </footer>
    </section>
  )
}
export default Footer;


