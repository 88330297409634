import React, { Fragment } from 'react';
import '../styles/error.css';
function Error404() {
    return (
        <Fragment>
            <div className="error-body">

                <a href="https://www.stuidapp.com/" target="_blank" rel="noreferrer">
                    <header className="top-header">
                    </header>
                    <div>
                        <div className="starsec"></div>
                        <div className="starthird"></div>
                        <div className="starfourth"></div>
                        <div className="starfifth"></div>
                    </div>
                    <div className="lamp__wrap">
                        <div className="lamp">
                            <div className="cable"></div>
                            <div className="cover"></div>
                            <div className="in-cover">
                                <div className="bulb"></div>
                            </div>
                            <div className="light"></div>
                        </div>
                    </div>
                    <section className="error">
                        <div className="error__content">
                            <div className="error__message message">
                                <h1 className="message__title">Page Not Found</h1>
                                <p className="message__text">We're sorry, the page you were looking for isn't found here. The link you followed may either be broken or no longer exists.</p>
                            </div>
                            <div className="error__nav e-nav">
								<meta http-equiv = "refresh" content = "1; url = https://www.stuidapp.com" />
                            </div>
                        </div>
                    </section>
                </a>
            </div>
        </Fragment>
    )
}

export default Error404;