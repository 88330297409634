import React from 'react';
import '../App.css';
import AskQus from '../Components/AskQus';

function Ask(){
    return(
        <div style={{backgroundColor: '#f2f2f2'}}>
            <AskQus></AskQus>
        </div>
    )
}
export default Ask;