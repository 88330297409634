import React from 'react';
import { Collapse } from 'antd';
import '../App.css';
import faq from '../img/faq.svg';
import { Image } from 'react-bootstrap';
import Footer from './Footer';



function Faq() {
    const text = (
        <p style={{ paddingLeft: 24 }}>
            A dog is a type of domesticated animal. Known for its loyalty and faithfulness, it can be found
            as a welcome guest in many households across the world.
        </p>
    );
    const { Panel } = Collapse;
    return (
        <React.StrictMode>
            <section id="FAQ" className="d-flex flex-column justify-content-center align-items-center mt-4 pb-4" style={{ width: '100vw' }}>
                <div className="d-flex flex-column pt-4 justify-content-center align-items-center">
                    <h1 className="m-0 p-0 font-weight-bolder text-uppercase">faq</h1>
                    <hr className="bg-primary" style={{ width: '7vw', borderWidth: '.2vw' }}></hr>
                </div>
                <div className="d-flex flex-column-reverse flex-lg-row justify-content-center align-items-center col-12 mb-4 pb-4">
                    <div className="col-12 col-lg-6 d-flex flex-column align-items-start justify-content-center mt-4">
                        <Collapse accordion bordered={false} defaultActiveKey={['1']} className="col-12">
                            <Panel header="This is panel header 1" key="1" className="font-weight-bolder" style={{ fontSize: '1.1em' }}>
                                {text}
                            </Panel>
                            <Panel header="This is panel header 2" key="2" className="font-weight-bolder" style={{ fontSize: '1.1em' }}>
                                {text}
                            </Panel>
                            <Panel header="This is panel header 3" key="3" className="font-weight-bolder" style={{ fontSize: '1.1em' }}>
                                {text}
                            </Panel>
                            <Panel header="This is panel header 3" key="4" className="font-weight-bolder" style={{ fontSize: '1.1em' }}>
                                {text}
                            </Panel>
                            <Panel header="This is panel header 5" key="5" className="font-weight-bolder" style={{ fontSize: '1.1em' }}>
                                {text}
                            </Panel>
                            <Panel header="This is panel header 6" key="6" className="font-weight-bolder" style={{ fontSize: '1.1em' }}>
                                {text}
                            </Panel>
                        </Collapse>
                    </div>
                    <div className="col-5 d-flex justify-content-center align-items-center mb-4 pt-4 pb-4">
                        <Image draggable="false" src={faq} style={{ height: 'auto', width: '20rem' }}></Image>
                    </div>
                </div>
            </section>
            <Footer></Footer>
        </React.StrictMode>
    )
} export default Faq