import React from 'react';
import '../App.css';
import { Image } from 'react-bootstrap';


function TestSection(props) {
    return (
        <section className={`container-fluid d-flex justify-content-center align-items-center col-11 col-md-9 mt-4 mb-4 pt-4 pb-4 ${props.row}`}>
            <div className="d-flex flex-column justify-content-center align-items-start col-lg-5 col-12 m-3 p-4">
                <h3 className="text-lg-left text-center col-12 font-weight-bolder">{props.title}</h3>
                <small className="text-lg-left text-center col-12">{props.description}</small>
            </div>
            <div className="col-lg-5 col-10 d-flex justify-content-center align-items-center">
                <Image draggable="false" src={props.img} style={{ height: 'auto', width: '20rem' }}></Image>
            </div>
        </section>
    )
}
export default TestSection;