import React from 'react';
import Footer from '../Components/Footer';


function Terms() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="d-flex flex-column justify-content-center align-items-center py-5" style={{ backgroundColor: '#f2f2f2', scrollMarginTop: '5rem' }}>
        <div class="container-fluid">
          <div class="d-flex flex-column justify-content-center align-items-center">

            <div class="col-xs-12 text-center pt-5  mx-4">
              <h1 class="title">Refund Policy</h1>
              <br></br>
            </div>

            <div class="col-xs-12 col-sm-12  col-sm-offset-1 col-md-10 col-md-offset-2" style={{ width: '100vw' }}>
              <div class="content mx-4">
			  <h3>Cancellation Policy</h3>
<p>Mobile application is free for all users, customers can uninstall the app if they won’t continue to subscribe.</p>
<br></br>
<h3>Refund Policy</h3>
<ul>
<li>Stuid Learning App refunds the amount if the customer cannot watch the classes due to our technical issue.</li>
<li>The refund request has to be made at support@stuidapp.com with your email ID and registered mobile number or by calling us on +919961024500. </li>
<li>Refunds shall be made to the bank account within 10 working days. Mobile application having a demo for all classes as free, customer can check the classes before purchasing. </li>
<li>Stuid Learning App is a mobile application, through which you can download the video, to use in offline mode. So we won’t encourage any refund after purchasing the material.</li>
<li>A refund is possible only if the purchased version has not matched with the demo provided. Customers can request to cancel the subscription within 48 hours after the sale. </li>
<li>No refund is given after 48 hours of purchase. The cancel request has to be made at support@stuidapp.com with your registered username, email ID and mobile number used for the purchase or by calling us on +919961024500.</li>
<li>If the purchased standard needs to be changed, then the customer has to further request for the required subscription within 48 Hours after the sale,we will provide the same within seven working days </li>
<li>No refund is possible if they are not interested to continue</li>
</ul>
                
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  )
} export default Terms;