import React from 'react';
import '../App.css';
import TestSection from '../Components/TestSection1';
import Demo from '../Components/Demo';
import Footer from '../Components/Footer';
import Exam from '../img/exam.svg';
import Qus from '../img/qus.svg';
import onlineQqus from '../img/onlinequs.svg'


function Practice() {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="d-flex flex-column justify-content-center align-items-center pt-4" style={{ backgroundColor: '#f2f2f2' }}>
            <div className="container-fluid justify-content-center align-items-center d-flex flex-column pt-4 pb-4 mt-4 mb-4 col-md-9 col-10" style={{ width: '90vw' }}>
                <h3 className="m-0 p-0 text-center font-weight-lighter text-capitalize mb-2 pt-4" style={{ fontSize: '1.2em' }}>Mock Tests</h3>
                <h1 className="font-weight-bolder text-center p-0 m-0 text-capitalize" style={{ fontSize: '3em' }}>Test your learning and skills to crack<span className="textColor"> school and competitive exams</span></h1>
            </div>
            <TestSection img={Exam} row="flex-lg-row-reverse flex-column-reverse" title="Unit test" description="Unit tests are conducted to assess the summative evaluation of educating learning measure. This
            is an interaction of estimating student`s achievement. By unit test, the educator comes to know
            about the viability and the effectiveness of learning process."></TestSection>
            <TestSection img={Qus} row="flex-lg-row flex-column-reverse" title="Previous Questions papers" description="When the students practice questions from past year question papers, the coed will tend to get a
                rational awareness of the type of questions that will be asked, tricks involved, topic-wise marks
                distribution, etc. This helps you build confidence and helps you strategize to divide some
                time expeditiously and enhance your performance. The questions that the student solves for the
                last ten years might not directly get repeated however could have slight changes in the
                information, or have the direct application of equivalent concepts in a
                very similar approach. This can save time in the long term and keep you targeted."></TestSection>
            <TestSection img={onlineQqus} row="flex-lg-row-reverse flex-column-reverse" title="Test exams" description="Test exams aids in transferring mere learning and knowledge to the applicability to real-life
                situations and how well the student has understood the concept.  It helps to identify the
                knowledge gap, promote meaningful learning, metacognition and stimulates interest in learning.
                Testing encourages students to study and the tutors, as well as parents, can track and tap the
                ability or potential of the student.">
				</TestSection>
				
            <Demo></Demo>
            <Footer></Footer>
        </div>
    )
}
export default Practice;