import React from 'react';
import '../App.css';
import AskFeatures from '../Components/AskFeatures';
import Demo from '../Components/Demo';
import Footer from '../Components/Footer';
import ContactUs from '../Components/ContactUs'
import Classes from '../Components/Classes';

function AskQus() {
    React.useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
            <AskFeatures title="ASK"></AskFeatures>
            <Classes></Classes>
            <Demo></Demo>
            <ContactUs></ContactUs>
            <Footer></Footer>
        </>
    )
}
export default AskQus;

