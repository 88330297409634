import React from 'react';
import Footer from '../Components/Footer';
import Subject from '../Components/Subject';
import ClassVideo from '../Components/ClassVideo';
import Demo from '../Components/Demo';
import Mathematics from '../img/venn-diagram.svg';
import Biology from '../img/lab.svg';
import Physics from '../img/atom.svg';
import Chemistry from '../img/flask.svg';
import History from '../img/history.svg';
import geography from '../img/earth-globe.svg';

function Class8() {

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="d-flex flex-column justify-content-center align-items-center py-5" style={{ backgroundColor: '#f2f2f2',  scrollMarginTop: '5rem' }}>
            <div className="d-flex flex-column justify-content-center align-items-center container-fluid col-10 text-center mt-4 pt-3" >
                <h1 className="font-weight-light text-capitalize text-center p-0 m-0" style={{ fontSize: '1.2em' }}>Kerala Board</h1>
                <h1 className="font-weight-bolder text-center p-0 m-0" style={{ fontSize: '4.2em' }}><span className="textColor">8th </span>Standard</h1>
                <small className="pt-3">Our Kerala Board Class 8 covers all the elements that will allow the students to learn, ask and
                score better with test series.  Students of Class 8 can learn and understand with the use of
                animated videos, audios and activities, study material which strengthens the learning abilities of
                them. We offer classes for Mathematics, Biology, Physics, Chemistry, History, and Geography.</small>
            </div>
            <div className="container-fluid d-flex flex-lg-row flex-column justify-content-center align-items-center pt-3">
                <div className="d-flex flex-row">
                    <Subject bgcolor="bg-color" image={Mathematics} subject="Mathematics"></Subject>
                    <Subject bgcolor="bg-color5" image={Biology} subject="Biology"></Subject>
                </div>
                <div className="d-flex flex-row">
                    <Subject bgcolor="bg-color2" image={Physics} subject="Physics"></Subject>
                    <Subject bgcolor="bg-color3" image={Chemistry} subject="Chemistry"></Subject>
                </div>
                <div className="d-flex flex-row">
                    <Subject bgcolor="bg-color4" image={History} subject="History"></Subject>
                    <Subject bgcolor="bg-color1" image={geography} subject="Geography"></Subject>
                </div>
            </div>
            <ClassVideo video1="https://vimeo.com/576404847" video2="https://vimeo.com/576405989"></ClassVideo>
            <Demo></Demo>
            <Footer></Footer>
        </div>
    )
}
export default Class8;