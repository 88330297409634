import React from 'react';
// import { Image, Modal} from 'react-bootstrap';
import Home from './Screen/Home';
import Faq from './Components/Faq';
import Terms from './Screen/Terms';
import Class8 from './Screen/Class8';
import Class9 from './Screen/Class9';
import Class10 from './Screen/Class10';
import Plus1 from './Screen/Plus1';
import Plus2 from './Screen/Plus2';
import Learning from './Screen/Learning';
import Test from './Screen/Test';
import Header from './Navigation/Header';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Ask from './Screen/Ask';
import Privacy from './Screen/privacy';
import Copyright from './Components/copyright';
import Error404 from './Screen/error404';
import RefundPolicy from './Screen/RefundPolicy';
import Blog from './Screen/Blog';
import Support from './Screen/Support';
import Partners from './Screen/Partners';
import Careers from './Screen/Careers';
import Refer from './Screen/referandearn';

function App() {
  return (
    <>
      <Router>
        <Header></Header>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/Faq" component={Faq} />
          <Route path="/Terms" component={Terms} />
          <Route path="/Learning" component={Learning} />
          <Route path="/Test" component={Test} />
          <Route path="/Ask" component={Ask} />
          <Route path="/Class8" component={Class8} />
          <Route path="/Class9" component={Class9} />
          <Route path="/Class10" component={Class10} />
          <Route path="/Plus1" component={Plus1} />
          <Route path="/Plus2" component={Plus2} />
          <Route path="/Privacy" component={Privacy} />
          <Route path="/Copyright" component={Copyright} />
		  <Route path="/RefundPolicy" component={RefundPolicy} />
		  <Route path="/Blog" component={Blog} />
		  <Route path="/Partners" component={Partners} />
		  <Route path="/Support" component={Support} />
		  <Route path="/Careers" component={Careers} />
		  <Route path="/referandearn" component={Refer} />
          <Route component={Error404} />
        </Switch>
      </Router>
    </>
  )
}
export default App;
