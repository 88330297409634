import React from 'react';
import { Image, Modal, Form, Button } from 'react-bootstrap';
import '../App.css';
import mockup2 from '../img/boy.webp';
import phone from '../img/smartphone.svg';
import Tablet from '../img/tablet.svg';
import playStore from '../img/google-play-badge.webp';
import AppStore from '../img/App_store.svg';

function HomeDemo() {
    const [show, setShow] = React.useState(false);

    const handleModal = () => show ? setShow(false) : setShow(true);

    return (
        <section id="Demo" className="d-flex flex-column align-items-center py-5" style={{ width: '100vw', backgroundColor: '#282728', scrollMarginTop: '5rem' }}>
            {/* Modal */}
            <Modal show={show}
                animation={true}
                centered
                // dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title" onHide={handleModal}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h3 className="m-0">Start your journey with Stuid Learning App</h3>
                        <small>Fill the below form and we will reach you in no time!</small>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form method="post" action={process.env.PUBLIC_URL + "/demo.php"}>
                        <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Your Name</Form.Label>
                            <Form.Control type="text" placeholder="Name" name="contactName" required />
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control type="email" placeholder="Email" name="contactEmail" required />
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Contact Number</Form.Label>
                            <Form.Control type="tel" placeholder="Number" name="contactPhone" required />
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Our Classes</Form.Label>
                            <Form.Control as="select" name="contactClass" required >
                                <option>8th Standard</option>
                                <option>9th Standard</option>
                                <option>10th Standard</option>
                                <option>11th Standard</option>
                                <option>12th Standard</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlTextarea1"  >
                            <Form.Label>Your Message</Form.Label>
                            <Form.Control as="textarea" rows={3} name="contactMessage" required />
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Button className="col-12 rounded-pill font-weight-bolder" variant="primary" type="submit">Send</Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                {/* <Modal.Footer className="col-12">
                    <Button className="col-12 rounded-pill mt-n4 font-weight-bolder" onClick={handleModal}>Submit</Button>
                </Modal.Footer> */}
            </Modal>
            <div className="d-flex flex-column justify-content-center align-items-center col-md-8 col-lg-10 col-10" style={{ paddingBlock: '5vh', width: '100vw' }}>
                <h3 className="m-0 p-0 text-center font-weight-lighter text-capitalize mb-2 text-white" style={{ fontSize: '1.2em' }}>Get started with a free trial now.</h3>
                <h5 className="m-0 text-white text-center font-weight-bolder demo_text pb-2 text-capitalize" style={{ fontSize: '4em' }}>Book a free Counselling Session and Demo</h5>
                <h5 className="m-0 p-0 text-center font-weight-lighter text-capitalize text-white col-lg-10" style={{ fontSize: '1.2em', lineHeight: 1.6 }}>When using demonstration, there is a peaceful cycle that will permit the students to have a clear understanding of the topic at hand. By taking a demo class with stuid learning app, the student will fall in love with learning. Individuals can likewise impart qualities and thoughts through demonstrations</h5>
            </div>
            <div style={{ cursor: 'pointer' }} className="bg-primary rounded-pill shadow-sm my-3" onClick={handleModal}>
                <h3 className="text-capitalize font-weight-bold m-0 p-3 pl-4 pr-4" style={{ fontSize: '1.2em', color: 'white' }}>BOOK DEMO<i className="fa fa-arrow-circle-o-down ml-2"></i></h3>
            </div>

            <div className="col-lg-10 col-md-8 col-9 platformbox d-flex flex-column justify-content-center align-items-end flex-lg-row-reverse pt-lg-4  my-5" style={{ borderRadius: '1em' }}>
                <div className="col-12 col-xl-6 d-flex flex-column justify-content-center pb-xl-5 align-self-center" style={{ paddingLeft: '3vw', paddingRight: '3vw' }}>
                    <h3 className="m-0 p-0 text-center font-weight-lighter text-capitalize mb-2 text-white pt-4" style={{ fontSize: '1.2em' }}>One step ahead Be a step ahead</h3>
                    <h2 className="font-weight-bolder text-white text-capitalize m-0 pb-4 text-lg-left text-center" style={{ fontSize: '3em' }}>stuid learning app is the right choice for all your needs</h2>
                    {/* <h4 className="font-weight-lighter text-white pt-3 m-0" style={{ fontSize: '1.5em' }}>A user interface designed for the classroom</h4> */}
                    <div className="d-flex flex-row justify-content-center align-items-center">
                        <div className="d-flex flex-column justify-content-center align-items-center m-3">
                            <Image draggable="false" src={phone} style={{ height: 'auto', width: '1.6rem' }}></Image>
                            <h3 className="text-white pt-3 m-0" style={{ fontSize: '1.2em' }}>Mobile</h3>
                            <h3 className="font-weight-lighter text-white m-0 pt-1 text-center" style={{ fontSize: '1.2em' }}>iOS & Android</h3>
                        </div>
                        <div className="d-flex flex-column justify-content-center align-items-center m-3">
                            <Image draggable="false" src={Tablet} style={{ height: 'auto', width: '1.6rem' }}></Image>
                            <h3 className="text-white pt-3 m-0" style={{ fontSize: '1.2em' }}>Tablets</h3>
                            <h3 className="font-weight-lighter text-white m-0 pt-1 text-center" style={{ fontSize: '1.2em' }}>Android & iPad</h3>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-xl-6 d-flex justify-content-center align-items-center overflow-hidden">
                    <Image draggable="false" src={mockup2} id="landingPageimg1" style={{ height: 'auto', width: '30rem' }}></Image>
                </div>
            </div>
            <div className="d-flex flex-row mb-3">
                <div style={{ cursor: 'pointer' }}>
                    <Image src={playStore} onClick={() => window.open("https://play.google.com/store/apps/details?id=com.stuid.main")} className="m-1" style={{ height: 'auto', width: '9rem' }}></Image>
                </div>
                <div style={{ cursor: 'pointer' }}>
                    <Image src={AppStore} onClick={() => window.open("https://apps.apple.com/in/app/stuid-learning-app/id1575872556")} className="m-1" style={{ height: 'auto', width: '8rem' }}></Image>
                </div>
            </div>
        </section>
    )
}
export default HomeDemo;