import React from 'react';
// import { Image} from 'react-bootstrap';
import ReactPlayer from 'react-player';
// import pattern from '../img/pattern.png';

function Video() {
    return (
        <section className="pb-4" style={{ width: '100vw' }}>
            <div className="d-flex flex-column align-items-center">
                <div className="container-fluid justify-content-center align-items-center d-flex flex-column pt-4 pb-4 col-10" style={{ width: '100vw' }}>
                    <h3 className="m-0 p-0 text-center font-weight-lighter text-capitalize mb-2" style={{ fontSize: '1.2em' }}>see Video</h3>
                    <h2 className="font-weight-bolder text-center p-0 m-0 text-capitalize" style={{ fontSize: '3em' }}><span className="textColor">Here is what </span>our students say!</h2>
                    <h3 className="m-0 p-0 text-center font-weight-lighter mt-3" style={{ fontSize: '1.3em' }}>Learn on your pace, whenever you like, from wherever you like.</h3>
                </div>
                <div className="container-fluid d-flex justify-content-center align-items-center position-relative" style={{ width: '100vw' }}>
                    {/* <Image draggable="false" src={pattern} style={{ height: 'auto', width: '7rem', right: '22vw', marginTop: '20vh', position: 'absolute', opacity: '0.1' }}></Image> */}
                    <div className="mb-4 d-flex justify-content-center align-items-center " style={{ height: 'auto', widt: 'auto', borderRadius: '20px', zIndex: 10, overflow: 'hidden', }}>
                        <ReactPlayer controls={true} playing={false} loop={true} url='https://vimeo.com/576403656/8e4a2d349e' />
                    </div>
                    {/* <Image draggable="false" src={pattern} style={{ height: 'auto', width: '7rem', position: 'absolute', opacity: '0.1', top:'3rem', bottom: 'auto',right: 'auto', left:'auto', zIndex:999}}></Image> */}
                </div>
            </div>
        </section>

    )
}
export default Video;