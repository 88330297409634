import React from 'react';
import '../App.css';
import LearnFeatures from '../Components/LearnFeatures';
import Demo from '../Components/Demo';
import Footer from '../Components/Footer';
import ContactUs from '../Components/ContactUs'
import Classes from '../Components/Classes';

function Learning() {
    React.useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
            <LearnFeatures title="LEARN"></LearnFeatures>
            <Classes></Classes>
            <Demo></Demo>
            <ContactUs></ContactUs>
            <Footer></Footer>
        </>
    )
}
export default Learning;

