import React from 'react';
import '../App.css';
import Footer from '../Components/Footer';
import Features from '../Components/Features';
import HomeDemo from '../Components/HomeDemo';
import Testimonials from '../Components/testimonials';
import LandingPage from '../Components/LandingPage';
import Section from '../Components/Section';
import Video from '../Components/Video';
import ContactUs from '../Components/ContactUs';

function Home() {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="container-fluid m-0 p-0" style={{ width: '100vw', backgroundColor: '#f2f2f2' }}>
            <LandingPage></LandingPage>
            <Section></Section>
            <Features subHead="Features"></Features>
            <HomeDemo></HomeDemo>
            <Testimonials></Testimonials>
            <Video></Video>
            <ContactUs></ContactUs>
            <Footer></Footer>
        </div>
    );
}
export default Home;


