import React from 'react';
import '../App.css';
// import { Image } from 'react-bootstrap';
// import blob from '../img/blob.svg';
import { useHistory } from "react-router-dom";

function Classes() {
    const history = useHistory();
    return (
        <section id="Classes"  className="d-flex justify-content-center align-items-center flex-column" style={{ width: '100vw', backgroundColor: '#f2f2f2', scrollMarginTop: '5rem' }}>
            {/* <Image draggable="false" src={blob} className="img2"></Image> */}
            <div className="container2 d-flex justify-content-center align-items-center flex-column py-5" style={{ width: '100vw'}}>
                <div className="d-flex flex-column align-items-center justify-content-center container pt-4" style={{ paddingLeft: '3vw', paddingRight: '3vw' }}>
                    <h2 className="font-weight-bolder m-0 mb-3 text-center " style={{ fontSize: '3em' }}><span className="textColor"> Our Classes</span></h2>
                    {/* <h3 className="m-0 p-0 text-center font-weight-lighter text-capitalize mb-2" style={{ fontSize: '1.2em' }}>Lorem ipsum dolor sit amet consectetur adipisicing elit.</h3> */}
                </div>
                <div className="col-10 d-flex flex-column justify-content-center align-items-center px-0">
                    <div className="col-12 d-flex flex-lg-row flex-column align-items-center justify-content-center mt-4 px-0">
                        <div className="d-flex flex-column col-lg-4 col-12 col-md-10 justify-content-center align-items-center my-sm-1 my-lg-0 py-4 px-0 px-md-3 px-lg-2">
                            <div onClick={() => history.push('./Class8')}  className="bg-color d-flex flex-column justify-content-center align-items-center container-fluid py-5" style={{ borderRadius: '1rem',cursor:'pointer' }}>
                                <p className="m-0 font-weight-bold text-white position-absolute" style={{ fontSize: '7em', opacity: 0.1 }}>VIII</p>
                                <h4 className="m-0 text-white" style={{ fontSize: '3em' }}>8th Standard</h4>
                                <p className="font-weight-bold mb-4 text-uppercase text-white pt-3" style={{ fontSize: '1em'}}>Explore<i className="fa fa-long-arrow-right ml-2"></i></p>
                            </div>
                            {/* <div className="container-fluid d-flex align-items-center justify-content-center" style={{ height: '10vh' }}>
                                <h4 className="m-0">8th Standard</h4>
                            </div> */}
                        </div>
                        <div className="d-flex flex-column col-lg-4 col-12 col-md-10 justify-content-center align-items-center my-sm-1 my-lg-0 py-4 px-0 px-md-3 px-lg-2">
                            <div onClick={() => history.push('./Class9')} className="bg-color1 d-flex flex-column justify-content-center align-items-center container-fluid py-5" style={{ borderRadius: '1rem',cursor:'pointer' }}>
                                <p className="m-0 font-weight-bold text-white position-absolute" style={{ fontSize: '7em', opacity: 0.1 }}>IX</p>
                                <h4 className="m-0 text-white" style={{ fontSize: '3em' }}>9th Standard</h4>
                                <h3 className="font-weight-bold mb-4 text-uppercase text-white pt-3" style={{ fontSize: '1em'}}>Explore<i className="fa fa-long-arrow-right ml-2"></i></h3>
                            </div>
                            {/* <div className="container-fluid d-flex align-items-center justify-content-center" style={{ height: '10vh' }}>
                                <h4 className="m-0">9th Standard</h4>
                            </div> */}
                        </div>
                        <div className="d-flex flex-column col-lg-4 col-12 col-md-10 justify-content-center align-items-center my-sm-1 my-lg-0 py-4 px-0 px-md-3 px-lg-2">
                            <div  onClick={() => history.push('./Class10')} className="bg-color2 d-flex flex-column justify-content-center align-items-center container-fluid py-5" style={{ borderRadius: '1rem',cursor:'pointer' }}>
                                <p className="m-0 font-weight-bold text-white position-absolute" style={{ fontSize: '7em', opacity: 0.1 }}>X</p>
                                <p className="m-0 text-white" style={{ fontSize: '3em' }}>10th Standard</p>
                                <p className="font-weight-bold mb-4 text-uppercase text-white pt-3" style={{ fontSize: '1em'}}>Explore<i className="fa fa-long-arrow-right ml-2"></i></p>
                            </div>
                            {/* <div className="container-fluid d-flex align-items-center justify-content-center" style={{ height: '10vh' }}>
                                <h4 className="m-0">10th Standard</h4>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-12 d-flex flex-lg-row flex-column align-items-center justify-content-center px-0">
                        <div className="d-flex flex-column col-lg-4 col-12 col-md-10  justify-content-center align-items-center my-sm-1 my-lg-0 my-xl-4 py-4 px-0 px-md-3 px-lg-2">
                            <div  onClick={() => history.push('./Plus1')} className="bg-color3 bg-danger d-flex flex-column justify-content-center align-items-center container-fluid py-5" style={{ borderRadius: '1rem', cursor:'pointer' }}>
                                <p className="m-0 font-weight-bold text-white position-absolute" style={{ fontSize: '7em', opacity: 0.1 }}>XI</p>
                                <h4 className="m-0 text-white" style={{ fontSize: '3em' }}>11th Standard</h4>
                                <h3 className="font-weight-bold mb-4 text-uppercase text-white pt-3" style={{ fontSize: '1em'}}>Explore<i className="fa fa-long-arrow-right ml-2"></i></h3>
                            </div>
                            {/* <div className="container-fluid d-flex align-items-center justify-content-center" style={{ height: '10vh' }}>
                                <h4 className="m-0">11th Standard</h4>
                            </div> */}
                        </div>
                        <div className="d-flex flex-column col-lg-4 col-12 col-md-10  justify-content-center align-items-center my-sm-1 my-lg-0 my-xl-4 py-4 px-0 px-md-3 px-lg-2">
                            <div onClick={() => history.push('./Plus2')} className="bg-color4 bg-danger d-flex flex-column justify-content-center align-items-center container-fluid py-5" style={{ borderRadius: '1rem', cursor:'pointer' }}>
                                <p className="m-0 font-weight-bold text-white position-absolute" style={{ fontSize: '7em', opacity: 0.1 }}>XII</p>
                                <h4 className="m-0 text-white" style={{ fontSize: '3em' }}>12th Standard</h4>
                                <p className="font-weight-bold mb-4 text-uppercase text-white pt-3" style={{ fontSize: '1em'}}>Explore<i className="fa fa-long-arrow-right ml-2"></i></p>
                            </div>
                            {/* <div className="container-fluid d-flex align-items-center justify-content-center" style={{ height: '10vh' }}>
                                <h4 className="m-0">12th Standard</h4>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Classes;