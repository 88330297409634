import React from 'react';
import { Image } from 'react-bootstrap';
// import blob from '../img/blob.svg';
import VideoLesson from '../img/videoLessons.svg';
import OfflineVideo from '../img/OfflineVideo.svg';
import AudioLessons from '../img/AudioLessons.svg';
import Ebook from '../img/e-book.svg';


function LearnFeatures(props) {
    return (
        <section id="learn" className="d-flex justify-content-center align-items-center flex-column pt-5" style={{ width: '100vw', backgroundColor: '#f2f2f2'}}>
            {/* <Image draggable="false" src={blob} className="img2"></Image> */}
            <div className="container2 container-fluid d-flex justify-content-center align-items-center flex-column pt-2 pb-4" style={{ width: '100vw' }}>
                <div className="d-flex flex-column align-items-center justify-content-center container mb-4 pt-4" style={{ paddingLeft: '3vw', paddingRight: '3vw' }}>
                    <h2 className="font-weight-bolder m-0 mb-3 text-center " style={{ fontSize: '4.2em' }}> <span className="textColor">{props.title}</span></h2>
                    <p className="m-0 p-0 text-center font-weight-lighter text-capitalize mb-2 mx-5" style={{ fontSize: '1.2em' }}>Video lessons, audio lessons, offline video and study materials of the concepts</p>
                </div>
                <div className="col-12 col-lg-12 p-lg-0 m-lg-0 col-md-9 d-flex flex-column container-fluid justify-content-center align-items-center">
                    <div className="d-flex flex-lg-row flex-column col-lg-10 col-12 justify-content-center align-items-center">
                        <div className="learnFeatures col-lg-6 col-12 py-5 bg-white m-4 shadow-sm d-flex flex-column justify-content-center align-items-center pr-4 pl-4" style={{borderRadius: '1.4rem' }}>
                            <Image draggable="false" src={VideoLesson} style={{ width: '12rem', height: 'auto' }}></Image>
                            <h3 className="font-weight-bolder pt-4 text-center" style={{fontSize: '2.3em'}}>Video Lessons</h3>
                            <p className="text-center pt-3 text-black py-3 px-lg-5 font-weight-light" style={{fontSize: '1.2em'}}>Visual stimulation grabs students’ attention through 3D animated videos and explanations.
                            Students today unquestionably hope to be visually stimulated; not very many react well to the
                            utilization of text all alone. Visual stimulation empowers student interaction with the content or
                            in the subject.</p>
                        </div>
                        <div className="learnFeatures col-lg-6 col-12 py-5 bg-white m-4 shadow-sm d-flex flex-column justify-content-center align-items-center pr-4 pl-4" style={{borderRadius: '1.4rem' }}>
                            <Image draggable="false" src={OfflineVideo} style={{ width: '12rem', height: 'auto' }}></Image>
                            <h3 className="font-weight-bolder pt-4 text-center" style={{fontSize: '2.3em'}}>Offline Video</h3>
                            <p className="text-center pt-3 text-black py-3 px-lg-5 font-weight-light" style={{fontSize: '1.2em'}}>Offline video can be accessed anytime, anywhere leaving no gap in the learning process. The
                            best part of offline classes is that the student can re-read, revise and learn the concepts in subjects
                            and have better clarity and understanding. With the ability to view lectures over and over, there
                            is in-depth learning that takes place.</p>
                        </div>
                    </div>
                    <div className="d-flex flex-lg-row flex-column col-lg-10 col-12 justify-content-center align-items-center">
                        <div className="learnFeatures col-lg-6 col-12 py-5 bg-white m-4 shadow-sm d-flex flex-column justify-content-center align-items-center pr-4 pl-4" style={{borderRadius: '1.4rem' }}>
                            <Image draggable="false" src={AudioLessons} style={{ width: '12rem', height: 'auto' }}></Image>
                            <h3 className="font-weight-bolder pt-4 text-center" style={{fontSize:'2.3em'}}>Audio Lessons</h3>
                            <p className="text-center pt-3 text-black py-3 px-lg-5 font-weight-light" style={{fontSize:'1.2em'}}>Audio as a learning apparatus is an extremely significant strategy for capturing and presenting
                            the information. Audio provides a quick alternative to text for connecting with students and
                            providing up-to-date content, discussions, or lecture materials. Audio has a shown ability to work
                            with real commitment, permitting students to associate in different approaches.</p>
                        </div>
                        <div className="learnFeatures col-lg-6 col-12 py-5 bg-white m-4 shadow-sm d-flex flex-column justify-content-center align-items-center pr-4 pl-4" style={{borderRadius: '1.4rem' }}>
                            <Image draggable="false" src={Ebook} style={{ width: '12rem', height: 'auto' }}></Image>
                            <h3 className="font-weight-bolder pt-4 text-center" style={{fontSize:'2.3em'}}>Study Materials</h3>
                            <p className="text-center pt-3 text-black py-3 px-lg-5 font-weight-light" style={{fontSize:'1.2em'}}>Stuid learning app provides study materials to its students. With the ease and technology made handy,
                            online study materials have been more successful compared to traditional manuscripts and
                            textbooks it is adapted to students through various learning styles.  It facilitates a constructivist
                            and inquiry-based approach to learning.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default LearnFeatures;