import React from 'react';
import { Image } from 'react-bootstrap';

function Subject(props) {
    return (
        <div className="d-flex flex-column justify-content-center align-items-center m-3">
            <div className={`d-flex justify-content-center align-items-center ${props.bgcolor}`} style={{borderRadius: '2rem' }}>
                <Image draggable="false"  src={props.image} id="sub" className="m-4" style={{ width: '4rem', height: 'auto', zIndex: 999}}></Image>
            </div>
            <div className="font-weight-bolder pt-2 pb-2 text-capitalize" style={{ fontSize: '1.4em' }}>{props.subject}</div>
        </div>
    )
}
export default Subject;